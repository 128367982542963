<script lang="ts" setup>
interface Props {
        user: _VTI_TYPE_User | null;
        size?: string;
        tooltipPosition?: string;
        clickableAvatar?: boolean;
    }
    
    
    const props = defineProps({
  user: null,
  size: { default: 'w-6 h-6 lg:h-8 lg:w-8' },
  tooltipPosition: { default: 'top' },
  clickableAvatar: { type: Boolean, default: true }
});
    const onClick = (event: Event) => {
        if (!props.clickableAvatar) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        return props.user?.preview();
    };
</script>

<template>
    <div
        class="bg-center bg-cover rounded-full cursor-pointer bg-neutral-200"
        :class="[size, user?.isInactive ? 'grayscale opacity-75' : '']"
        :style="user ? `background-image: url(${user.avatar})` : ''"
        :data-tooltip="user ? user.name : null"
        :data-tooltip-location="tooltipPosition"
        @click="onClick"
    ></div>
</template>
